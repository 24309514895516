import * as React from 'react';
import MuiSwitch from '@mui/material/Switch';

import type { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

import styles from './Switch.module.pcss';

type SwitchProps = Omit<
  MuiSwitchProps,
  'checkedIcon' | 'classes' | 'color' | 'icon' | 'size' | 'sx'
>;

const Switch = ({ ...rest }: SwitchProps) => (
  <MuiSwitch
    disableRipple
    classes={{
      root: styles.root,
      switchBase: styles.switchBase,
      track: styles.track,
      thumb: styles.thumb,
      checked: styles.checked,
      disabled: styles.disabled,
    }}
    {...rest}
  />
);

export default Switch;
