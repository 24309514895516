import * as Sentry from '@sentry/react';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { v4 as uuid } from 'uuid';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import {
  getLocalTimeZone,
  today,
  parseDate,
  parseTime,
  CalendarDateTime,
} from '@internationalized/date';
import type { DateRange } from '@/design/DatePicker';

import type { WorkflowResponse, FolderResponse } from '../api/workflow';
import type {
  AssignedTo,
  DatasourceRuleset,
  DebuggerLog,
  TimeUnit,
  User,
  LastSeenData,
  WorkflowVersion,
  AccountUser,
} from '../types';

import { Node, Edge } from '@/features/graph';

import { useSentry } from '../ServerConfig';

const daysArray = ['yesterday', 'today'];

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const dayOfWeek = parseInt(moment().format('d'), 10);
const weeksArray = [
  ...weekDays.slice(dayOfWeek + 1),
  ...weekDays.slice(0, dayOfWeek + 1),
];

const monthsArray = Array(30)
  .fill(0)
  .map((_, index) =>
    moment()
      .subtract(29 - index, 'days')
      .format('M/D')
  );

export type TicksInterval = 'day' | 'month' | 'week';

export function getTicks(interval: TicksInterval) {
  switch (interval) {
    case 'day':
      return daysArray;
    case 'week':
      return weeksArray;
    case 'month':
    default:
      return monthsArray;
  }
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export function getMonthName(month: number) {
  return monthNames[month - 1];
}

export function formatMonthAndTime(dateString: string) {
  return moment(dateString).format('M/DD/YY h:mm A');
}

export function formatMonth(dateString: string) {
  return moment(dateString).format('M/DD/YYYY');
}

export function formatMonthReverse(dateString: string) {
  return moment(dateString).format('YYYY/MM/DD');
}

export function formatWithMonthName(dateString: string) {
  return moment(dateString).format('DD MMM YYYY');
}

export function addLeadingZero(digits: number): string {
  const str = digits.toString();

  if (str.length === 1) {
    return `0${str}`;
  } else return str;
}

export function generateLogsList(logs: $TSFixMe) {
  const newRows: DebuggerLog[] = [];
  logs.forEach((ruleSetLog: $TSFixMe) => {
    ruleSetLog.firedRules.forEach((ruleLog: $TSFixMe) => {
      newRows.push({
        workflowId: ruleSetLog.workflowId,
        ruleSet: ruleSetLog.name,
        ruleSetId: ruleSetLog.ruleSetId,
        ruleSetType: ruleSetLog.ruleSetType,
        rule: ruleLog.name,
        ruleType: ruleLog.ruleType,
        ruleId: ruleLog.ruleId,
        condition: ruleLog.condition ?? '',
        action: ruleLog.action ?? '',
        warning: ruleLog.warning,
        didExecute: ruleLog.didExecute,
        filterMatcher: ruleLog.filterMatcher,
        variableMap: ruleLog.variableMap,
        variableSetMap: ruleLog.variableSetMap,
        executionMode: ruleLog.executionMode,
        branch: ruleLog.branch,
        metadata: ruleLog?.metadata,
        rolloutPercentage: ruleLog?.rolloutPercentage ?? null,
        shadowExecution: ruleLog.shadowExecution ?? false,
      });
    });
  });

  return newRows;
}

export function formatFiredRulesList(ruleLog: any) {
  const newRows: any = [];
  ruleLog.forEach((log: any) => {
    newRows.push({
      workflowId: ruleLog.workflowId,
      ruleSet: ruleLog.name,
      ruleSetId: ruleLog.ruleSetId,
      ruleSetType: ruleLog.ruleSetType,
      rule: log.name,
      ruleType: log.ruleType,
      ruleId: log.ruleId,
      condition: log.condition,
      action: log.action,
      warning: log.warning,
      didExecute: log.didExecute,
      filterMatcher: log.filterMatcher,
      variableMap: log.variableMap,
      variableSetMap: log.variableSetMap,
      executionMode: log.executionMode,
      branch: log.branch,
    });
  });
}

const CALL_RESPONSE_TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const RUN_TYPES = {
  LIVE: 'LIVE',
  MOCK: 'MOCK',
};

export function buildDataSourceOptions(
  dataSourceRulesets: DatasourceRuleset[],
  lastSeenDataList: LastSeenData[]
) {
  return dataSourceRulesets.map((dataSource) => {
    const foundIndex = lastSeenDataList.findIndex(
      (data) => data.ruleSetId === dataSource.id
    );

    let mockData: any =
      foundIndex === -1 ? {} : lastSeenDataList[foundIndex].dataResponse;

    try {
      mockData = JSON.stringify(mockData, null, 2);
    } catch (err) {
      console.log(
        `Could not convert mockData to string for ruleset ${dataSource.id}`
      );
    }

    return {
      ruleSetId: dataSource.id,
      callResponse: CALL_RESPONSE_TYPES.SUCCESS,
      name: dataSource.name,
      runType: RUN_TYPES.MOCK,
      type: dataSource.type,
      mockData,
    };
  });
}

export function getTestExecution(validation: $TSFixMe, testResults: $TSFixMe) {
  return testResults[validation.id];
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeFirstCharacter(str: string) {
  return capitalize(str.toLowerCase());
}

export function buildUserString(user: User) {
  const { firstName, lastName, username } = user;
  if (firstName && lastName) {
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  }
  if (firstName) {
    return capitalize(firstName);
  }

  if (lastName) {
    return capitalize(lastName);
  }

  if (username) {
    return username;
  }

  return 'User';
}

export function getFullName(user: User) {
  const { firstName, lastName, username } = user;

  if (firstName && lastName) {
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  }

  if (firstName) {
    return capitalize(firstName);
  }

  if (username) {
    return username;
  }

  return 'User';
}

export function mergeRefs(refs: $TSFixMe) {
  return (value: $TSFixMe) => {
    refs.forEach((el: $TSFixMe) => {
      const ref = el;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null && !isUndefined(ref)) {
        ref.current = value;
      }
    });
  };
}

export function throttle(
  this: $TSFixMe,
  callback: $TSFixMe,
  interval: $TSFixMe
) {
  let enableCall = true;

  return (...args: $TSFixMe[]) => {
    if (!enableCall) return;

    enableCall = false;
    callback.apply(this, args);
    setTimeout(() => {
      enableCall = true;
    }, interval);
  };
}

export function sanitizeRule(str: string) {
  return DOMPurify.sanitize(str, {
    ALLOWED_TAGS: ['span'],
    ALLOWED_ATTR: ['class'],
  });
}

export const VIEW_STATES = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  RESOLVED: 'RESOLVED',
  ERROR: 'ERROR',
};

export function sendSentryError(err: $TSFixMe, options: $TSFixMe) {
  if (!useSentry) {
    return;
  }

  const { tags, contexts } = options;
  Sentry.captureException(err, (scope) => {
    scope.clear();
    if (tags) {
      Object.keys(tags).forEach((key) => scope.setTag(key, tags[key]));
    }
    if (contexts) {
      Object.keys(contexts).forEach((key) =>
        scope.setContext(key, contexts[key])
      );
    }

    return scope;
  });
}

export function getAssignedToId(assignedTo: AssignedTo) {
  if (assignedTo.type === 'user') {
    return `user-${assignedTo.id}`;
  }

  return `team-${assignedTo.id}`;
}

interface BracketEvent {
  start: {
    row: number;
    column: number;
  };
  end: {
    row: number;
    column: number;
  };
  action: string; // 'insert', 'remove'
  lines: string[];
  id?: number;
}

export function withMatchedBrackets(value: string, event: BracketEvent) {
  try {
    const textLines = value.split('\n');

    const { action, lines, end } = event;
    const { row, column } = end;

    if (action === 'insert') {
      if (lines?.[0] === '{' && lines.length === 1) {
        textLines[row] = `${textLines[row].slice(0, column)}}${textLines[
          row
        ].slice(column)}`;
      }
    }
    return textLines.join('\n');
  } catch (err) {
    return value;
  }
}

export function calculateMarkers(
  _data: Array<{ total?: number; date: 'string' }>,
  threshold?: number
) {
  if (!_data || !_data.length || !threshold) {
    return [0, 0];
  }

  let sum = 0;
  _data.forEach((entry: $TSFixMe) => {
    sum += entry.total;
  });

  const avg = sum / _data.length;

  const firstMarker = (1 - threshold / 100) * avg;
  const secondMarker = (1 + threshold / 100) * avg;

  return [firstMarker, secondMarker];
}
export const WORKFLOW_OUTCOMES = {
  ONBOARDING_PASS: 'PASS',
  ONBOARDING_REJECT: 'REJECT',
  ONBOARDING_INVESTIGATE: 'INVESTIGATE',
};

export const CUSTOMER_FACING_WORKFLOW_OUTCOMES = {
  PASS: 'ONBOARDING_PASS',
  REJECT: 'ONBOARDING_REJECT',
  INVESTIGATE: 'ONBOARDING_INVESTIGATE',
};

export function trimValues(obj: Record<string, any>) {
  const trimmed = {};
  for (const key in obj) {
    const val = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    trimmed[key] = val;
  }
  return trimmed;
}

export function getVersionData(versions?: WorkflowVersion[]) {
  const hasSaveOption = versions?.length
    ? !!versions.find((version) => !!version.canSave)
    : false;

  const displayedWorkflowVersion = versions?.find(
    (version) => version.canSave || (!hasSaveOption && !version.canRestore)
  );

  const displayedWorkflowVersionId = displayedWorkflowVersion?.versionId;

  return {
    hasSaveOption,
    displayedWorkflowVersion,
    displayedWorkflowVersionId,
  };
}

export function isUser(obj: { firstName?: string; lastName?: string }) {
  if (typeof obj !== 'object') return false;

  return !!obj.firstName || !!obj.lastName;
}

type PropertyType = 'BOOLEAN' | 'TEXT' | 'NUMBER' | 'ARRAY';

// converts from string representation (from input value) to Array object to send to the backend (ApiDetailsPanel + NewWorkflowSuccessModal)
// can also handle other types if needed
export function getFormattedValue(
  value: string | number | boolean | [],
  type: PropertyType
) {
  let formattedValue = value;

  if (type === 'ARRAY' && typeof value === 'string') {
    const replacedQuotes = value.replaceAll(`'`, `"`);

    if (value.trim() === '') {
      formattedValue = [];
    } else {
      formattedValue = JSON.parse(replacedQuotes);
    }
  }

  return formattedValue;
}

// https://malcolmkee.com/blog/typesafe-call-all/
interface Callback<Params extends any[]> {
  (...args: Params): void;
}

export const callAll = <Params extends any[]>(
  ...fns: Array<Callback<Params> | undefined>
) => (...args: Params) => fns.forEach((fn) => fn && fn(...args));

export function timeDiff(stringVal1: string, stringVal2: string) {
  const dateOne = moment(stringVal1);
  const dateTwo = moment(stringVal2);
  return dateOne.diff(dateTwo, 'seconds');
}

export function isUndefined(x: any): x is undefined {
  return typeof x === 'undefined';
}

export function isNumber(x: unknown): x is number {
  return typeof x === 'number';
}

export function catchifyJsonParse(jsonString: string) {
  try {
    const input = JSON.parse(jsonString);
    return [null, input];
  } catch {
    return [true, null];
  }
}

export function isObjectLike(
  candidate: unknown
): candidate is Record<string, unknown> {
  return typeof candidate === 'object' && candidate !== null;
}

export function isWorkflowResponse(arg: unknown): arg is WorkflowResponse {
  return isObjectLike(arg) && arg.workflow !== null;
}

export function isFolderResponse(arg: unknown): arg is FolderResponse {
  return isObjectLike(arg) && arg.folder !== null;
}

type DatasourceNode = Node & {
  data: Node['data'] & {
    ruleset: DatasourceRuleset;
  };
};

export function isDatasourceType(arg: unknown): arg is Node {
  if (isObjectLike(arg)) {
    if (
      arg.type === 'DATA_SOURCE' ||
      arg.type === 'API_CALL' ||
      arg.type === 'ASYNC' ||
      arg.type === 'GRPC'
    ) {
      return true;
    }
  }

  return false;
}

export function isDatasourceNode(arg: Node | Edge): arg is DatasourceNode {
  return (
    isObjectLike(arg) &&
    isDatasourceType(arg) &&
    !isUndefined(arg.data) &&
    !isUndefined(arg.data.ruleset) &&
    arg.data.ruleset !== null
  );
}

export const getSecondsMultiplier = (type: TimeUnit) => {
  let multiplier = 0;
  switch (type) {
    case 'Days':
      multiplier = 60 * 60 * 24;
      break;
    case 'Hours':
      multiplier = 60 * 60;
      break;
    case 'Minutes':
      multiplier = 60;
      break;
    default:
      multiplier = 0;
      break;
  }

  return multiplier;
};

export function getIntervalAndUnit(
  x: number
): readonly [interval: number, timeUnit: TimeUnit] {
  const dayToSeconds = getSecondsMultiplier('Days');
  const hourToSeconds = getSecondsMultiplier('Hours');
  const minuteToSeconds = getSecondsMultiplier('Minutes');
  if (x % dayToSeconds === 0) {
    return [x / dayToSeconds, 'Days'];
  }

  if (x % hourToSeconds === 0) {
    return [x / hourToSeconds, 'Hours'];
  }

  if (x % minuteToSeconds === 0) {
    return [x / minuteToSeconds, 'Minutes'];
  }

  return [x, 'Minutes'];
}

export function generateKey() {
  return uuid();
}

export const updateUrl = debounce(
  ({ value, parsed, url, history }) => {
    const newQueryObject = {
      ...parsed,
      page: 1,
      query: value ? value : null,
    };

    const stringifiedQuery = queryString.stringify(newQueryObject);

    history.push(`${url}?${stringifiedQuery}`);
  },
  300,
  { leading: false, trailing: true }
);

export function truncate({
  string,
  length = 15,
}: {
  string: string;
  length?: number;
}): string {
  if (!length || string.length < length) {
    return string;
  }

  return `${string.slice(0, length)}...`;
}

export function getEmail(user: $TSFixMe) {
  return user.realName || user.username;
}

export function isHttpsUrl(str: string) {
  let url;
  try {
    url = new URL(str);
    if (url.protocol === 'https:') {
      return true;
    }
  } catch {
    return false;
  }

  return false;
}

export function hasProperty(obj: Record<any, any>, prop: string) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function autoFocusRef(node: HTMLElement | null) {
  node?.focus();
}

export function isEmptyObject(x: unknown) {
  return isObjectLike(x) && Object.keys(x).length === 0;
}

export function formatDateFilterValueString(dates: DateRange) {
  let startDate = today(getLocalTimeZone()).toString();
  let endDate = today(getLocalTimeZone()).toString();

  if (!dates.start.year || !dates.end.year) {
    return {
      startDate,
      endDate,
    };
  }

  const newEndDate = dates.end.set({ second: 59 });

  startDate = dates.start.toString();
  endDate = newEndDate.toString();

  return {
    startDate,
    endDate,
  };
}

export function toDateFilterValue(encodedStr?: string, type?: 'start' | 'end') {
  if (!encodedStr) {
    return null;
  }

  const arr = encodedStr.split('T');
  const date = parseDate(arr[0]);
  const time = parseTime(arr[1]);

  const convertedDateValue = new CalendarDateTime(
    date.year,
    date.month,
    date.day,
    time.hour,
    time.minute,
    type === 'end' ? 59 : 0
  );

  return convertedDateValue;
}

export function getDefaultDates() {
  const start = today(getLocalTimeZone());
  const end = today(getLocalTimeZone());

  const defaultStart = new CalendarDateTime(
    start.year,
    start.month,
    start.day,
    0,
    0,
    0
  );
  const defaultEnd = new CalendarDateTime(
    end.year,
    end.month,
    end.day,
    23,
    59,
    59
  );

  return {
    defaultStart,
    defaultEnd,
  };
}

export function getKeyByValue(object: Record<string, string>, value: string) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function assertUnreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

export function formatVersion(name: string, id?: number) {
  if (name && id) {
    return `${name} [ID: ${id}]`;
  }

  if (name) {
    return name;
  }

  if (id) {
    return `Unnamed version [ID: ${id}]`;
  }

  return '-';
}

export function selectActiveUsers(
  users: Array<AccountUser>
): Array<AccountUser> {
  return users.filter((user) => user.activeStatus === 'ACTIVE');
}
