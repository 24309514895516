import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

import styles from './Loading.module.pcss';

const Loading = ({ size }: $TSFixMe) => (
  <>
    <PuffLoader size={size || 60} color="#2196F3" />
  </>
);

export const NewLoading = () => {
  return (
    <div className={styles.container} data-testid="loader">
      <PuffLoader className={styles.newLoader} color="#2196F3" />
    </div>
  );
};

export default Loading;
