import * as React from 'react';

import { flagClient } from '@/utils/launchdarkly';

function useLDLoadingState() {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    let unmounted = false;

    flagClient
      .waitUntilReady()
      .then(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  return isLoading;
}

export default function useIsLoadingFlags() {
  const isLoadingLDFlags = useLDLoadingState();

  return isLoadingLDFlags;
}
