import * as React from 'react';

import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import {
  QueryClientProvider,
  QueryClient,
  QueryCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DndProvider } from 'react-dnd';
import { DndContext } from '@dnd-kit/core';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer, toast } from 'react-toastify';
import { Router } from 'react-router-dom';

import AuthenticationProvider from './context/AuthenticationProvider';
import InitializeApiClient from './context/ApiClient';

import history from './utils/history';
import { setupSentry } from './utils/setup';

import AppAndRedirects from './AppAndRedirects';

import { Devtools } from '@/devtools';

import 'react-toastify/dist/ReactToastify.min.css';

setupSentry();

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-size: .875rem;
          font-family: var(--font-inter),"Helvetica","Arial",sans-serif;
        }
      `,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5000, // dedupes same query fetched within 5 seconds
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.state.data !== undefined) {
        if (error instanceof Error) {
          toast.error(`Something went wrong: ${error.message}`);
        }
      }
    },
  }),
});

const Providers = ({ children }: $TSFixMe) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider>
            <InitializeApiClient />
            <ReactQueryDevtools />
            {/* NOTE: DndProvider is for React Dnd, DndContext is for @dnd-kit */}
            <DndProvider backend={HTML5Backend}>
              <DndContext>
                <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  newestOnTop={false}
                  hideProgressBar
                  closeOnClick
                  rtl={false}
                />
                <CssBaseline />
                <Devtools />
                {children}
              </DndContext>
            </DndProvider>
          </AuthenticationProvider>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  </StyledEngineProvider>
);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <div>
      <Providers>
        <AppAndRedirects />
      </Providers>
    </div>
  </React.StrictMode>
);
