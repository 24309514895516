import * as Sentry from '@sentry/react';

import { useSentry, environment } from '../ServerConfig';

import { getEmail } from './';

export function setupSentry() {
  if (useSentry) {
    Sentry.init({
      dsn:
        'https://620be783dc1e4edf91257e98ff215183@o472062.ingest.sentry.io/5505136',
      integrations: [new Sentry.BrowserTracing()],
      environment,
      release: __RELEASE_VERSION__, // inlined during vite build
      tracesSampleRate: 1.0,
    });
  }
}

export function setSentryUser(user: $TSFixMe) {
  const email = getEmail(user);

  if (useSentry) {
    Sentry.setUser({ email });
  }
}
