import * as React from 'react';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './Button.module.pcss';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'plain'
    | 'unstyled';
  size?: 'large' | 'small';
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      size = 'large',
      className,
      children,
      isLoading,
      ...rest
    },
    ref
  ) => {
    return isLoading ? (
      <button
        ref={ref}
        className={clsx(
          styles.button,
          styles[variant],
          styles[size],
          className
        )}
        {...rest}
        disabled
      >
        <span className={styles.spinnerContainer}>
          <CircularProgress size="20px" color="inherit" />
        </span>
        <span className={styles.hidden}>{children}</span>
      </button>
    ) : (
      <button
        ref={ref}
        className={clsx(
          styles.button,
          styles[variant],
          styles[size],
          className
        )}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
