import configManager from './utils/configManager';

export const apiUrl = `${getUrl()}/api`;
export const authUrl = `${getUrl()}/auth/signin`;
export const bareUrl = getUrl();
export const externalApi = `${getUrl()}/v1`;
export const grpcEndpoint = getGrpcEndpoint();
export const authAudience = getAudience();
export const authClientId = getClientId();
export const authDomain = getAuthDomain();
export const shouldUseAuth0 = getUseAuth0();
export const shouldUseSaml =
  configManager.getConfigValue('SAML_ENABLED') || false;
export const useSentry = getUseSentry();
export const useXstateInspector = false;
export const environment = getEnvironment();
export const dbConn = getDbConn();
// TODO: make this configurable
export const shouldUseOkta = isOktaEnabled();
export const persistenceDisabled = configManager.getConfigValue(
  'NO_PERSIST_MODE',
  false
);
export const selfManagedUsersEnabled = configManager.getConfigValue(
  'SCIM_ENABLED',
  false
);

function getDbConn() {
  const DB_NAME = configManager.getConfigValue('DB_NAME');

  if (DB_NAME) {
    return DB_NAME;
  }

  const url = window.location.origin.toString();
  if (url.includes('dev.sliderule.io')) {
    return 'Dev-DB';
  }

  if (url.includes('sandbox.sliderule.io')) {
    return 'Sandbox-DB';
  }

  if (url.includes('demo.sliderule.io')) {
    return 'Demo-DB';
  }
  if (url.includes('staging.sliderule.io')) {
    return 'Staging-DB';
  }
  return null;
}

function isOktaEnabled() {
  const isOktaEnabled = configManager.getConfigValue('OKTA_ENABLED');
  if (isOktaEnabled) {
    return isOktaEnabled;
  }
  return false;
}

function getUrl() {
  const URL = configManager.getConfigValue('API_URL');

  if (URL) {
    return URL;
  }
  /* eslint-disable no-undef */

  // Might just be able to use NODE_ENV for this check too, but it's hard to say whether setting
  // NODE_ENV to something unexpected will have downstream effects
  if (__CYPRESS_ENV__ === 'true') {
    return 'http://api-local:8000';
  }

  if (process.env.NODE_ENV === 'test') {
    return 'http://api-mock.usemodernlogic.com';
  }
  /* eslint-enable */

  const url = window.location.origin.toString();
  if (url.includes('localhost') || url.includes('0.0.0.0')) {
    return 'http://localhost:8001';
  }

  if (url.includes('internal-modernlogic-web-poc-82987984')) {
    return 'http://internal-modernlogic-api-poc-1944100891.us-west-2.elb.amazonaws.com';
  }

  if (url.includes('prod2')) {
    return 'https://api-prod2.sliderule.io';
  }
  if (url.includes('staging')) {
    return 'https://api-staging.sliderule.io';
  }
  if (url.includes('fraudmachine')) {
    return 'https://api.fraudmachine.com';
  }
  if (url.includes('app.modernlogic.fastops')) {
    return 'https://api.modernlogic.fastops.co';
  }
  if (url.includes('dev-old.modernlogic.fastops')) {
    return 'https://api-dev-old.modernlogic.fastops.co';
  }
  if (url.includes('fast')) {
    return 'https://api-dev.modernlogic.fastops.co';
  }
  if (url.includes('dev')) {
    return 'https://api-dev.sliderule.io';
  }
  if (url.includes('sandbox')) {
    return 'https://api-sandbox.sliderule.io';
  }
  if (url.includes('brick')) {
    return 'https://api-brick.usemodernlogic.com';
  }
  if (url.includes('mocafi')) {
    return 'https://api-mocafi.usemodernlogic.com';
  }
  if (url.includes('upfront')) {
    return 'https://api-upfront.usemodernlogic.com';
  }
  if (url.includes('demo')) {
    return 'https://api-demo.sliderule.io';
  }
  if (url.includes('clutch')) {
    if (url.includes('usemodernlogic')) {
      return 'https://api-clutch.usemodernlogic.com';
    } else {
      return 'https://api-clutch.sliderule.io';
    }
  }

  if (url.includes('usemodernlogic')) {
    return 'https://api.usemodernlogic.com';
  } else {
    return 'https://api.sliderule.io';
  }
}

function getAudience() {
  const AUTH_AUDIENCE = <string>configManager.getConfigValue('AUTH_AUDIENCE');

  if (AUTH_AUDIENCE) {
    return AUTH_AUDIENCE;
  }

  if (__CYPRESS_ENV__ === 'true') {
    return '';
  }
  const url = window.location.origin.toString();
  if (url.includes('localhost') || url.includes('0.0.0.0')) {
    return 'localhost:8000/api';
  }
  if (url.includes('cash')) {
    return 'https://block.usemodernlogic.com/api';
  }
  if (
    url.includes('mocafi') ||
    url.includes('upfront') ||
    url.includes('prod2')
  ) {
    return 'https://mocafi.usemodernlogic.com/api';
  }
  if (url.includes('staging')) {
    return 'https://staging.sliderule.io/api';
  }
  if (url.includes('fraudmachine')) {
    return 'https://tops.usemodernlogic.com/api';
  }
  if (url.includes('app.modernlogic.fastops')) {
    return 'https://prod-fast.usemodernlogic.com/api';
  }
  if (url.includes('fast')) {
    return 'https://fast.usemodernlogic.com/api';
  }
  if (url.includes('dev')) {
    return 'https://dev.sliderule.io/api';
  }
  if (url.includes('sandbox')) {
    return 'https://sandbox.sliderule.io/api';
  }
  if (url.includes('brick')) {
    return 'https://brick.usemodernlogic.com/api';
  }
  if (url.includes('demo')) {
    return 'https://demo.sliderule.io/api';
  }

  return 'https://api.usemodernlogic.com/api';
}

function getClientId() {
  const AUTH_CLIENT_ID = <string>configManager.getConfigValue('AUTH_CLIENT_ID');

  if (AUTH_CLIENT_ID) {
    return AUTH_CLIENT_ID;
  }

  if (__CYPRESS_ENV__ === 'true') {
    return '';
  }
  const url = window.location.origin.toString();
  if (url.includes('localhost') || url.includes('0.0.0.0')) {
    return 'rBwkM4elKEk2WcyRYUj2ugdTn2QwrnuJ';
  }
  if (url.includes('cash')) {
    return 'ootRJQJTVJ6Q3Tmch44ifrUhPV2begyD';
  }

  if (url.includes('prod2')) {
    return 'YJhUYPiAvz5cPAnlqOiBeHMlfpaos3HP';
  }

  if (url.includes('mocafi') || url.includes('upfront')) {
    return 'qgo3DFhXtZRhVdNeJbAZiM7WwhvnGMiv';
  }
  if (url.includes('demo')) {
    return 'xnfdzgIeLDArAWYQpZE0dNVqHFFUOgfZ';
  }

  if (url.includes('staging')) {
    return 'NtYWKtI5rKYRngJA3w1GhgiwDb4eZ8xd';
  }
  if (url.includes('app.modernlogic.fastops')) {
    return 'A0EaWqcXvAXzdAqlTdVconJwbRmi2rif';
  }
  if (url.includes('fraudmachine')) {
    return 'T3DMiwy1V6RP0E4voGCqCv91RTdwSQuk';
  }
  if (url.includes('fast')) {
    return '2jkk8AP1HmItqRCv12aOL4QmKheAXbun';
  }
  if (url.includes('dev')) {
    return 'F3ogfsyItDx0jrKXi1H19Wtah7fMIvau';
  }
  if (url.includes('sandbox')) {
    return '94DFd4VCHXWzDdVxBnAVd5wYwBQ9XvT9';
  }
  if (url.includes('brick')) {
    return 'Z2iLrLqv3B5ohj1ud5CTRKdgksQ5sxM4';
  }
  if (url.includes('clutch')) {
    return 'dPFe0yKhnyJdNi06F86oYImb8rZGhvbv';
  }

  return 'ylx95xAKTRrR5GG01BPbliyAe6Hxq2JO';
}

function getUseAuth0() {
  const AUTH0_ENABLED = configManager.getConfigValue('AUTH0_ENABLED');

  if (AUTH0_ENABLED !== '') {
    return AUTH0_ENABLED;
  }

  if (__CYPRESS_ENV__ === 'true') {
    return false;
  }
  const url = window.location.origin.toString();

  if (url.includes('localhost') || url.includes('0.0.0.0')) {
    return false;
  }
  if (url.includes('cash')) {
    return true;
  }
  if (url.includes('staging')) {
    return true;
  }
  if (url.includes('app.modernlogic.fastops')) {
    return true;
  }
  if (url.includes('fraudmachine')) {
    return true;
  }
  if (url.includes('fast')) {
    return true;
  }
  if (url.includes('dev')) {
    return true;
  }
  if (url.includes('sandbox')) {
    return true;
  }
  if (url.includes('brick')) {
    return false;
  }
  if (
    url.includes('mocafi') ||
    url.includes('upfront') ||
    url.includes('prod2')
  ) {
    return true;
  }
  if (url.includes('app') || url.includes('clutch')) {
    return true;
  }

  if (url.includes('demo')) {
    return true;
  }

  return false;
}

function getAuthDomain() {
  const AUTH_DOMAIN = <string>configManager.getConfigValue('AUTH_DOMAIN');

  if (AUTH_DOMAIN) {
    return AUTH_DOMAIN;
  }

  const url = window.location.origin.toString();
  if (
    url.includes('dev') ||
    url.includes('sandbox') ||
    url.includes('staging') ||
    url.includes('demo')
  ) {
    return 'sliderule-auth.us.auth0.com';
  }

  return 'usemodernlogic.us.auth0.com';
}

function getUseSentry() {
  const SENTRY_ENABLED = configManager.getConfigValue('SENTRY_ENABLED');

  if (SENTRY_ENABLED !== '') {
    return SENTRY_ENABLED;
  }

  if (__CYPRESS_ENV__ === 'true') {
    return false;
  }
  const url = window.location.origin.toString();

  if (url.includes('localhost') || url.includes('0.0.0.0')) {
    return false;
  }
  if (url.includes('cash')) {
    return true;
  }
  if (url.includes('staging')) {
    return true;
  }
  if (url.includes('app.modernlogic.fastops')) {
    return true;
  }
  if (url.includes('fraudmachine')) {
    return true;
  }
  if (url.includes('fast')) {
    return true;
  }
  if (url.includes('dev')) {
    return false;
  }
  if (url.includes('sandbox')) {
    return true;
  }
  if (url.includes('brick')) {
    return true;
  }
  if (url.includes('mocafi')) {
    return true;
  }
  if (url.includes('upfront')) {
    return true;
  }
  if (url.includes('app') || url.includes('clutch') || url.includes('prod2')) {
    return true;
  }
  if (url.includes('demo')) {
    return true;
  }
  return false;
}

function getEnvironment() {
  const ENVIRONMENT = <string>(
    configManager.getConfigValue('SHIELDRULE_ENVIRONMENT')
  );

  if (ENVIRONMENT) {
    return ENVIRONMENT;
  }

  if (__CYPRESS_ENV__ === 'true') {
    return 'dev';
  }
  const url = window.location.origin.toString();

  if (url.includes('localhost') || url.includes('0.0.0.0')) {
    return '';
  }
  if (url.includes('cash')) {
    return 'block';
  }
  if (url.includes('staging')) {
    return 'staging';
  }
  if (url.includes('app.modernlogic.fastops')) {
    return 'prodfast';
  }
  if (url.includes('fraudmachine')) {
    return 'tops';
  }
  if (url.includes('fast')) {
    return 'fast';
  }
  if (url.includes('dev')) {
    return 'dev';
  }
  if (url.includes('sandbox')) {
    return 'sandbox';
  }
  if (url.includes('brick')) {
    return 'brick';
  }
  if (url.includes('mocafi') || url.includes('prod2')) {
    return 'mocafi';
  }
  if (url.includes('demo')) {
    return 'demo';
  }
  return 'production';
}

function getGrpcEndpoint() {
  const GRPC_ENDPOINT = configManager.getConfigValue('GRPC_ENDPOINT');

  if (GRPC_ENDPOINT) {
    return GRPC_ENDPOINT;
  }

  const url = window.location.origin.toString();
  if (url.includes('localhost') || url.includes('0.0.0.0')) {
    return 'localhost:9520';
  }
  // change
  if (url.includes('cash')) {
    return 'grpc-production.cash-ml-poc-production.squarecloudservices.com';
  }

  if (url.includes('prod2')) {
    return 'grpc-prod2.usemodernlogic.com';
  }
  if (url.includes('staging')) {
    return 'grpc-staging.sliderule.io';
  }
  if (url.includes('fraudmachine')) {
    return 'grpc.fraudmachine.com';
  }
  if (url.includes('app.modernlogic.fastops')) {
    return 'grpc.modernlogic.fastops.co';
  }
  if (url.includes('dev-old.modernlogic.fastops')) {
    return 'grpc-dev-old.modernlogic.fastops.co';
  }
  if (url.includes('fast')) {
    return 'grpc-dev.modernlogic.fastops.co';
  }
  if (url.includes('dev')) {
    return 'grpc-dev.sliderule.io';
  }
  if (url.includes('sandbox')) {
    return 'grpc-sandbox.sliderule.io';
  }
  if (url.includes('brick')) {
    return 'grpc-brick.usemodernlogic.com';
  }
  if (url.includes('mocafi')) {
    return 'grpc-mocafi.usemodernlogic.com';
  }
  if (url.includes('upfront')) {
    return 'grpc-upfront.usemodernlogic.com';
  }
  if (url.includes('demo')) {
    return 'grpc-demo.sliderule.io';
  }
  if (url.includes('clutch')) {
    return 'grpc-clutch.usemodernlogic.com';
  }

  return 'grpc.usemodernlogic.com';
}

const config = {
  // TODO: default to local debugging (localhost:port)
  apiUrl,
  authUrl,
  bareUrl,
  externalApi,
  grpcEndpoint,
  authAudience,
  authClientId,
  authDomain,
  shouldUseAuth0,
  useSentry,
  useXstateInspector,
  environment,
  dbConn,
  shouldUseOkta,
};

export default config;
