import { User, AccountUser, PaginatedResponse, AuthOverride } from '../types';

import client from './client';
import { apiUrl, bareUrl } from '../ServerConfig';

import { isObjectLike, isUndefined } from '../utils';

export function getUsers(params = {}) {
  return client
    .get<PaginatedResponse<AccountUser>>(`${apiUrl}/user/v2`, { params })
    .then((response) => ({
      users: response.data.retList,
      count: response.data.total,
    }));
}

export function getFeatures() {
  return client.get(`${apiUrl}/user/feature_flags`).then((res) => {
    return res.data;
  });
}

export function mimic(mimic: $TSFixMe, username: $TSFixMe, password: $TSFixMe) {
  const obj = {
    username,
    password,
    mimicUsername: mimic,
  };
  return client
    .post(`${bareUrl}/auth/mimic`, obj)
    .then((retVal) => retVal.data);
}

export function getUserAlerts(user: $TSFixMe) {
  const link = user._links.alerts.href;
  return client.get(link).then((response) => response.data._embedded.alert);
}

function assertIsUser(val: unknown): val is User {
  if (isObjectLike(val) && !isUndefined(val.username)) {
    return true;
  }

  throw new Error('Invalid user response.');
}

export function getCurrentUser() {
  return client.get<User>(`${bareUrl}/auth/me`).then((user) => {
    assertIsUser(user.data);
    return user.data;
  });
}

export function getCustomAuthOptions() {
  return client
    .get<AuthOverride>(`${bareUrl}/auth/overrides`)
    .then((results) => {
      return results.data;
    });
}

export function createUser(
  email: $TSFixMe,
  firstName: $TSFixMe,
  lastName: $TSFixMe,
  admin: $TSFixMe
) {
  const req = {
    username: email,
    firstName,
    lastName,
    admin,
  };
  return client.post(`${bareUrl}/auth/createUser`, req).catch((err) => {
    if (err.response.status === 400) {
      return Promise.reject(err.response.data);
    }
    return Promise.reject('Unable to create user.');
  });
}

export function updateUser(id: $TSFixMe, user: $TSFixMe) {
  return client.put(`${apiUrl}/user/${id}/update`, user);
}

export function getUnreadAlertCount() {
  const suffix = '?status=UNTRIAGED,ASSIGNED&tabFilter=assignedToMe';

  return client.get(`${apiUrl}/alert${suffix}`).then((response) => {
    return response.data.total;
  });
}
