import * as React from 'react';

type UseEventListenerOptions = boolean | AddEventListenerOptions | undefined;

export default function useEventListener<K extends keyof DocumentEventMap>(
  type: K,
  listener: (event: DocumentEventMap[K]) => void,
  options: UseEventListenerOptions = {}
) {
  const savedListener = React.useRef<typeof listener>();

  React.useEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  React.useEffect(() => {
    const eventListener: typeof listener = (e) => {
      if (savedListener.current) {
        savedListener.current(e);
      }
    };

    document.addEventListener(type, eventListener, options);

    return () => {
      document.removeEventListener(type, eventListener, options);
    };
  }, [type, options]);
}
