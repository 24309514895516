import * as React from 'react';
import clsx from 'clsx';

import styles from './Typography.module.pcss';

type TextProps = {
  component?: string;
  children: React.ReactNode;
  className?: string;
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  weight?: 'bold' | 'normal' | 'light';
  title?: string;
};

export const Text = React.forwardRef<any, TextProps>(
  (
    {
      component = 'p',
      size = '',
      weight = '',
      children,
      className = ' ',
      ...rest
    },
    ref
  ) =>
    React.createElement(
      component,
      {
        ref,
        className: clsx(
          styles.heading,
          styles[size],
          styles[weight],
          className
        ),
        ...rest,
      },
      children
    )
);

Text.displayName = 'Text';

type HeadingProps = {
  component?: string;
  children: React.ReactNode;
  className?: string;
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  weight?: 'bold' | 'normal' | 'light';
  title?: string;
};

export const Heading = React.forwardRef<any, HeadingProps>(
  (
    {
      component = 'h1',
      size = '',
      weight = '',
      children,
      className = '',
      ...rest
    },
    ref
  ) =>
    React.createElement(
      component,
      {
        ref,
        className: clsx(
          styles.heading,
          styles[component],
          styles[size],
          styles[weight],
          className
        ),
        ...rest,
      },
      children
    )
);

Heading.displayName = 'Heading';

interface CodeProps {
  children: React.ReactNode;
  className?: string;
}

export const Code = (props: CodeProps) => {
  const { children, className } = props;
  return <code className={clsx(styles.code, className)}>{children}</code>;
};
