/* 
  This file creates a provider with a common interface for dealing with authentication through
  auth0 or through local storage.
*/

import * as React from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth, Security } from '@okta/okta-react';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import client from '@/api/client';

import {
  shouldUseAuth0,
  shouldUseOkta,
  shouldUseSaml,
  authDomain,
  authClientId,
  authAudience,
  dbConn,
  bareUrl,
} from '../ServerConfig';
import { getToken as getTokenFromLocalStorage } from './auth';
import history from '../utils/history';
import useSearchParams from '@/hooks/useSearchParams';

type LogoutFn = (params?: { redirectTo?: string }) => void;

interface LoginParams {
  redirect?: string;
  screenHint?: 'signup' | string;
}

interface AuthProviderContext {
  isLoading: boolean;
  isAuthenticated: boolean;
  logout: LogoutFn;
  login: (data?: LoginParams) => void;
  getToken: () => Promise<string | null>;
  refreshToken: () => Promise<string | null>;
}

const AuthenticationContext = React.createContext<AuthProviderContext | null>(
  null
);

async function attemptRefresh(oldToken: string | null) {
  try {
    const { data } = await client.post(`${bareUrl}/auth/refresh`, {
      oldToken,
    });
    if (data.refreshAccepted) {
      // set token
      localStorage.setItem(
        'tokens',
        JSON.stringify({
          token: data.newToken,
          type: 'regular',
        })
      );
      return data.newToken;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

// redirect callback for auth0
const onRedirectCallback = (appState: $TSFixMe) => {
  history.replace((appState && appState.returnTo) || window.location.pathname);
};

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
  history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};

interface AuthProvider {
  children: React.ReactNode;
}

const AuthenticationProvider = ({ children }: AuthProvider) => {
  if (shouldUseSaml) {
    return <SamlProvider>{children}</SamlProvider>;
  }

  if (shouldUseAuth0) {
    return (
      <Auth0Provider
        domain={authDomain}
        clientId={authClientId}
        dbConn={dbConn}
        redirectUri={window.location.origin}
        audience={authAudience}
        scope="read:current_user update:current_user_metadata"
        onRedirectCallback={onRedirectCallback}
        skipRedirectCallback={window.location.pathname === '/credentials'}
      >
        <Auth0StatusProvider>{children}</Auth0StatusProvider>
      </Auth0Provider>
    );
  }

  if (shouldUseOkta) {
    const oktaAuth = new OktaAuth({
      issuer: authDomain,
      clientId: authClientId,
      redirectUri: `${window.location.origin}/login/callback`,
    });

    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <OktaStatusProvider>{children}</OktaStatusProvider>
      </Security>
    );
  }

  return <LocalAuthStatusProvider>{children}</LocalAuthStatusProvider>;
};

const OktaStatusProvider = ({ children }: AuthProvider) => {
  const { oktaAuth, authState } = useOktaAuth();

  const login = React.useCallback(
    async (data?: LoginParams) =>
      await oktaAuth.signInWithRedirect({
        originalUri: data?.redirect,
      }),
    [oktaAuth]
  );
  const logout = async () => await oktaAuth.signOut();

  const handleLogout = async () => {
    // TODO: remove localStorage code
    localStorage.setItem('tokens', JSON.stringify(null));
    await logout();
  };

  const getToken = React.useCallback(() => {
    return new Promise<string | null>((resolve) => {
      const token = oktaAuth.getAccessToken();
      resolve(token || null);
    });
  }, [oktaAuth]);

  const refreshToken = React.useCallback(() => {
    return Promise.resolve('success');
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        isLoading: !authState,
        isAuthenticated: !!authState?.isAuthenticated,
        logout: handleLogout,
        login,
        getToken,
        refreshToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const Auth0StatusProvider = ({ children }: AuthProvider) => {
  const {
    isLoading,
    isAuthenticated,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const handleLogout = React.useCallback<LogoutFn>(
    (params) => {
      // TODO: remove localStorage code
      localStorage.setItem('tokens', JSON.stringify(null));

      if (params) {
        logout({
          returnTo: params.redirectTo,
        });
      } else {
        logout();
      }
    },
    [logout]
  );

  const getToken = React.useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: authAudience,
      scope: 'read:current_user update:current_user_metadata',
    });

    return token;
  }, [getAccessTokenSilently]);

  const refreshToken = React.useCallback(() => {
    return Promise.resolve('success');
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        logout: handleLogout,
        login: (data) => {
          loginWithRedirect({
            appState: { returnTo: data?.redirect },
            // eslint-disable-next-line
            screen_hint: data?.screenHint,
          });
        },
        getToken,
        refreshToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

// NOTE: only used in local development
const LocalAuthStatusProvider = ({ children }: AuthProvider) => {
  const queryClient = useQueryClient();

  const isLoading = false;
  const getAuthenticationStatus = React.useCallback(() => {
    const tokenObject = getTokenFromLocalStorage();

    if (!tokenObject) {
      return false;
    }

    return true;
  }, []);

  const [isAuthenticated, setIsAuthenticated] = React.useState(
    getAuthenticationStatus()
  );

  React.useEffect(() => {
    const storageListener = () => {
      setIsAuthenticated(getAuthenticationStatus());
    };
    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, [getAuthenticationStatus]);

  const handleLogout = React.useCallback(() => {
    localStorage.setItem('tokens', JSON.stringify(null));
    setIsAuthenticated(false);
    queryClient.removeQueries();
  }, [queryClient]);

  const handleLogin = React.useCallback<(data?: any) => void>((data) => {
    localStorage.setItem('tokens', JSON.stringify(data));
    setIsAuthenticated(true);
  }, []);

  const getToken = React.useCallback(
    () =>
      new Promise<string | null>((resolve) => {
        const token = getTokenFromLocalStorage()?.token ?? null;

        resolve(token);
      }),
    []
  );

  const refreshToken = React.useCallback(async () => {
    const token = await getToken();

    return attemptRefresh(token);
  }, [getToken]);

  return (
    <AuthenticationContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        logout: handleLogout,
        login: handleLogin,
        getToken,
        refreshToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const SamlProvider = ({ children }: AuthProvider) => {
  const queryClient = useQueryClient();

  const isLoading = false;

  const history = useHistory();
  const [searchParams] = useSearchParams();

  const getAuthenticationStatus = React.useCallback(() => {
    const tokenObject = getTokenFromLocalStorage();

    if (!tokenObject) {
      const token = searchParams.get('token');

      if (token) {
        localStorage.setItem(
          'tokens',
          JSON.stringify({ token, type: 'regular' })
        );
        history.replace('/');

        return true;
      }

      return false;
    } else {
      return true;
    }
  }, [history, searchParams]);

  const [isAuthenticated, setIsAuthenticated] = React.useState(
    getAuthenticationStatus()
  );

  React.useEffect(() => {
    const storageListener = () => {
      setIsAuthenticated(getAuthenticationStatus());
    };
    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, [getAuthenticationStatus]);

  const handleLogout = React.useCallback(() => {
    localStorage.setItem('tokens', JSON.stringify(null));
    setIsAuthenticated(false);
    queryClient.removeQueries();
  }, [queryClient]);

  const handleLogin = React.useCallback(() => {
    window.location.replace(`${bareUrl}/auth/saml2/authenticate`);
  }, []);

  const getToken = React.useCallback(
    () =>
      new Promise<string | null>((resolve) => {
        const token = getTokenFromLocalStorage()?.token ?? null;

        resolve(token);
      }),
    []
  );

  const refreshToken = React.useCallback(async () => {
    const token = await getToken();

    return attemptRefresh(token);
  }, [getToken]);

  return (
    <AuthenticationContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        logout: handleLogout,
        login: handleLogin,
        getToken,
        refreshToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export function useAuth(): AuthProviderContext {
  const context = React.useContext(AuthenticationContext);
  if (!context) {
    throw new Error(
      'Cannot access AuthenticationContext context outside of AuthenticationContext.Provider'
    );
  }
  return context;
}

export default AuthenticationProvider;
