/* 
  NOTE: this file contains helpers for accessing token from local storage
  This should eventually only affect code running in development (or environments configured to use localStorage for token)
*/

// TODO: move to a util

interface GetTokenResult {
  token?: string;
}

export function getToken(): GetTokenResult | null {
  const tokens = localStorage.getItem('tokens');

  if (tokens) {
    return JSON.parse(tokens);
  }
  return null;
}
