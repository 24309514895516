import * as React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'query-string';

import { useAuth } from './AuthenticationProvider';
import client from '../api/client';
import { environment } from '@/ServerConfig';

const getCustomHeaders = () => {
  const serviceHeaderInfo = window.localStorage.getItem('serviceHeaderInfo');

  const groupHeaderInfo = window.localStorage.getItem('groupHeaderInfo');

  const customHeaders: Record<string, string> = {};

  if (serviceHeaderInfo) {
    const { headerName, headerValue } = JSON.parse(serviceHeaderInfo);
    customHeaders[headerName] = headerValue;
  }

  if (groupHeaderInfo) {
    const { headerName, headerValue } = JSON.parse(groupHeaderInfo);
    customHeaders[headerName] = headerValue;
  }

  return customHeaders;
};

const InitializeApiClient = () => {
  const { getToken, logout } = useAuth();

  React.useEffect(() => {
    client.interceptors.request.use(async (config = { headers: {} }) => {
      const token = await getToken();

      let headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...config.headers,
      };

      if (environment === 'dev' || environment === 'localhost') {
        const customHeaders = getCustomHeaders();
        headers = Object.assign(headers, customHeaders);
      }

      return {
        ...config,
        paramsSerializer: function (params: any) {
          return qs.stringify(params, { arrayFormat: 'bracket' });
        },
        headers,
      };
    });

    client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = error?.response?.status;
        const message = error?.response?.data;

        // if request was cancelled, don't toast or anything
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        if ((status === 400 || status === 404) && typeof message === 'string') {
          toast.error(`Error: ${message}`);
        } else if (status === 403) {
          toast.warn('Session expired. Please log in again.', {
            toastId: 'sessionExpired',
          });
          logout();
        } else if (error?.response?.data && error?.response?.data?.message) {
          const message = error.response.data.message;
          toast.error(`Error: ${message}`, {
            toastId: 'messageErrorResponse',
          });
        } else {
          toast.error('There was an issue. Please contact support', {
            toastId: 'networkError',
          });
        }
        return Promise.reject(error);
      }
    );
  }, [getToken, logout]);

  return null;
};

export default InitializeApiClient;
