/* 
  ...For the times when you don't feel like writing a margin
*/

import * as React from 'react';

import styles from './Spacer.module.pcss';

const sizes = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '48px',
};

type Props = {
  size?: ('xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl') | number;
  direction?: 'x' | 'y';
};

const Spacer = ({ direction = 'y', size = 'sm' }: Props) => {
  const spacing = typeof size === 'number' ? size : sizes[size];
  return (
    <div
      className={styles.spacer}
      style={{
        width: direction === 'x' ? spacing : 'auto',
        height: direction === 'y' ? spacing : 'auto',
      }}
    />
  );
};

export default Spacer;
