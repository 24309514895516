export interface GlobalConfig {
  API_URL?: string;
  GRPC_ENDPOINT?: string;
  AUTH_AUDIENCE?: string;
  AUTH_CLIENT_ID?: string;
  AUTH_DOMAIN?: string;
  AUTH0_ENABLED?: string;
  SAML_ENABLED?: string;
  OKTA_ENABLED?: string;
  SENTRY_ENABLED?: string;
  SHIELDRULE_ENVIRONMENT?: string;
  DB_NAME?: string;
  LAUNCHDARKLY_CLIENT_ID?: string;
  NO_PERSIST_MODE?: string;
  SCIM_ENABLED?: string;
}

// casts 'true' | 'false' strings to boolean, 'null' to null. Otherwise just returns string
function readConfigValue(value: string) {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  if (value === 'null') {
    return null;
  }

  return value;
}

function createConfigManager() {
  const config: GlobalConfig = {};

  function initialize() {
    if (window.__CONFIG__) {
      const _config = window.__CONFIG__;

      (Object.keys(_config) as Array<keyof GlobalConfig>).forEach((key) => {
        const val = _config[key];
        config[key] = val;
      });
    }
  }

  function getConfigValue(
    key: keyof GlobalConfig,
    fallback?: string | boolean
  ) {
    const val = config[key];

    if (val) {
      return readConfigValue(val);
    }

    if (fallback) {
      return fallback;
    }

    return '';
  }

  return {
    initialize,
    getConfigValue,
  };
}

const configManager = createConfigManager();
configManager.initialize();

export default configManager;
