import * as React from 'react';
import clsx from 'clsx';

import useEventListener from '@/hooks/useEventListener';
import useLocalStorage from '@/hooks/useLocalStorage';

import { Heading } from '@/design/Typography';
import Switch from '@/design/Switch';
import Spacer from '@/design/Spacer';
import { Input, Label } from '@/design/Input';
import Button from '@/design/Button';

import styles from './Devtools.module.pcss';
import useCustomAuthOptions from '@/query/useCustomAuthOptions';
import Loading from '@/design/Loading';
import { Select } from '@/design/Select';
import { LocalAuthOverride } from '@/types';

function useToggleDevtools(toggle: () => void) {
  useEventListener('keydown', (e) => {
    if (e.ctrlKey || e.metaKey) {
      if (e.key === 'j') {
        toggle();
      }
    }
  });
}

const CustomAuthOptions = () => {
  const customAuthOptions = useCustomAuthOptions();
  const [
    serviceHeaderInfo,
    setServiceHeaderInfo,
  ] = useLocalStorage<LocalAuthOverride>('serviceHeaderInfo', {
    headerName: '',
    headerValue: '',
  });

  const [
    groupHeaderInfo,
    setGroupHeaderInfo,
  ] = useLocalStorage<LocalAuthOverride>('groupHeaderInfo', {
    headerName: '',
    headerValue: '',
  });

  let existingServiceHeader, existingGroupHeader;

  if (serviceHeaderInfo) {
    existingServiceHeader = serviceHeaderInfo.headerValue;
  }

  if (groupHeaderInfo) {
    existingGroupHeader = groupHeaderInfo.headerValue;
  }
  const [currentServiceOverride, setCurrentServiceOverride] = React.useState(
    existingServiceHeader || ''
  );

  const [currentGroupOverride, setCurrentGroupOverride] = React.useState(
    existingGroupHeader || ''
  );

  if (customAuthOptions.isLoading) {
    return (
      <div className={styles.loader}>
        <Loading />
      </div>
    );
  }

  if (customAuthOptions.isError) {
    return <div>Unable to load auth header override options</div>;
  }

  const serviceHeaderName = customAuthOptions.data.trustedServiceHeaderName;
  const groupHeaderName = customAuthOptions.data.trustedGroupHeaderName;

  return (
    <div>
      <Heading component="h3">Auth header override</Heading>
      <div>
        <Label>Service</Label>
        <Select
          value={currentServiceOverride}
          onChange={(e) => {
            setServiceHeaderInfo({
              headerName: serviceHeaderName,
              headerValue: e.target.value,
            });

            setCurrentServiceOverride(e.target.value);
          }}
        >
          <option value="">None</option>
          {customAuthOptions.data.serviceOptions?.map((o: string) => {
            return (
              <option key={`service-${o}`} value={o}>
                {o}
              </option>
            );
          })}
        </Select>
        <Label>Group</Label>
        <Select
          value={currentGroupOverride}
          onChange={(e) => {
            setGroupHeaderInfo({
              headerName: groupHeaderName,
              headerValue: e.target.value,
            });
            setCurrentGroupOverride(e.target.value);
          }}
        >
          <option value="">None</option>
          {customAuthOptions.data.groupOptions?.map((o: string) => {
            return (
              <option key={`service-${o}`} value={o}>
                {o}
              </option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export const Devtools = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  useToggleDevtools(() => setIsOpen((prev) => !prev));

  const [localStorageOverrideMap, setLocalStorageOverrides] = useLocalStorage<
    Record<string, boolean>
  >('flag-overrides', {});

  const localStorageOverrides = Object.keys(localStorageOverrideMap).map(
    (key) => {
      return {
        flag: key,
        enabled: localStorageOverrideMap[key],
      };
    }
  );

  const toggleLocalOverride = (flag: string, newValue: boolean) => {
    setLocalStorageOverrides((existingMap) => {
      const newOverrideMap = {
        ...existingMap,
        [flag]: newValue,
      };

      return newOverrideMap;
    });
  };

  const addLocalOverride = (flag: string) => {
    setLocalStorageOverrides((existingMap) => {
      const newOverrideMap = {
        ...existingMap,
        [flag]: true,
      };

      return newOverrideMap;
    });
  };

  const deleteLocalOverride = (flag: string) => {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    setLocalStorageOverrides((existingMap) => {
      const newOverrideMap = {
        ...existingMap,
      };

      delete newOverrideMap[flag];

      return newOverrideMap;
    });
  };

  return (
    <div
      className={clsx(styles.devtools, isOpen ? styles.open : styles.closed)}
    >
      <div>
        <Heading component="h3">Local flag overrides</Heading>
        <Spacer />
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const formData = new FormData(e.currentTarget);

            const flag = formData.get('flag') as string;

            if (!flag) {
              return;
            }

            addLocalOverride(flag);

            e.currentTarget.reset();
          }}
        >
          <Input size="small" name="flag" placeholder="Flag name" />
          <Spacer size="sm" />
          <Button variant="secondary" size="small">
            Add override
          </Button>
        </form>
        <Spacer size="md" />
        <ul className={styles.unstyledList}>
          {localStorageOverrides.map((override, index) => (
            <li key={override.flag} className={styles.localOverride}>
              <div>
                <Switch
                  onChange={(e) => {
                    toggleLocalOverride(override.flag, e.target.checked);
                  }}
                  checked={override.enabled}
                />
                <span>{override.flag}</span>
              </div>

              <Button
                size="small"
                variant="warning"
                onClick={() => deleteLocalOverride(override.flag)}
              >
                Delete override
              </Button>
            </li>
          ))}
        </ul>
      </div>
      <CustomAuthOptions />
    </div>
  );
};
