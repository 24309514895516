import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import MuiSelect from '@mui/material/Select';
import clsx from 'clsx';

import DownArrowIcon from '../../icons/DownArrowIcon';

import styles from './Select.module.pcss';

type InputSize = 'large' | 'small';

interface StyledInputProps {
  size: InputSize;
  className?: string;
}

const StyledInput = ({ size, className, ...rest }: StyledInputProps) => (
  <InputBase
    classes={{
      root: clsx(styles.inputRoot, styles[size], className),
      input: styles.input,
    }}
    {...rest}
  />
);

// NOTE: eventually we'll want this to accept all html select attributes
// will be easier to accomplish that once we're off MUI

interface SelectProps {
  id?: string;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  children?: React.ReactNode;
  size?: InputSize;
  className?: string;
  label?: React.ReactNode;
  displayEmpty?: boolean;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
}

/* Allowing users to override InputBase with their own classname. May need to update this in future */
export const Select = ({
  children,
  size = 'large',
  className,
  onChange,
  ...rest
}: SelectProps) => (
  <MuiSelect
    variant="outlined"
    IconComponent={DownArrowIcon}
    input={<StyledInput className={className} size={size} />}
    classes={{
      select: clsx(styles.root, styles.select, styles[size]),
      outlined: styles.outlined,
      iconOutlined: styles.iconOutlined,
    }}
    MenuProps={{
      classes: {
        paper: styles.menu,
        list: styles.list,
      },
    }}
    native={true}
    onChange={(e) => {
      if (onChange) {
        onChange((e as unknown) as React.ChangeEvent<HTMLSelectElement>);
      }
    }}
    {...rest}
  >
    {children}
  </MuiSelect>
);
