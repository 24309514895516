import * as React from 'react';
import clsx from 'clsx';

import styles from './Input.module.pcss';

type BaseInputProps = Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>;

export interface InputProps extends BaseInputProps {
  size?: 'small' | 'large';
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, size = 'large', ...rest }, ref) => (
    <input
      className={clsx(styles.input, styles[size], className)}
      ref={ref}
      {...rest}
    />
  )
);
Input.displayName = 'Input';

interface LabelProps extends React.ComponentPropsWithoutRef<'label'> {
  children: React.ReactNode;
}

export const Label = ({ children, ...rest }: LabelProps) => (
  <label className={clsx(styles.label)} {...rest}>
    {children}
  </label>
);
