import * as React from 'react';

import { useAuth } from './context/AuthenticationProvider';

import Loading from './design/Loading';
import AppErrorBoundary from './components/AppErrorBoundary';

import useIsLoadingFlags from './hooks/useIsLoadingFlags';
import useLogoutIfErrorParamExists from './hooks/useLogoutIfErrorParamExists';

import lazy from './utils/lazyWithCatch';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));

import './styles/global.css';

import styles from './app.module.pcss';

const App = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const isLoadingFlags = useIsLoadingFlags();

  useLogoutIfErrorParamExists();

  if (isLoading || isLoadingFlags) {
    return (
      <div className={styles.loader}>
        <Loading />
      </div>
    );
  }

  return (
    <AppErrorBoundary>
      <React.Suspense
        fallback={
          <div className={styles.loader}>
            <Loading />
          </div>
        }
      >
        {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </AppErrorBoundary>
  );
};

export default App;
