import * as React from 'react';
import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

import styles from './Button.module.pcss';

type ConditionalLinkProps =
  | ({ href?: never } & LinkProps)
  | ({
      href: string;
      to?: never;
    } & React.ComponentPropsWithoutRef<'a'>);

export type LinkButtonProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'plain'
    | 'unstyled';
  size?: 'large' | 'small';
} & ConditionalLinkProps;

export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    { variant = 'primary', size = 'large', className, children, ...rest },
    ref
  ) => {
    if ('href' in rest) {
      return (
        <a
          ref={ref}
          className={clsx(
            styles.button,
            styles[variant],
            styles[size],
            className
          )}
          {...rest}
        >
          {children}
        </a>
      );
    } else {
      return (
        <Link
          ref={ref}
          className={clsx(
            styles.button,
            styles[variant],
            styles[size],
            className
          )}
          {...rest}
        >
          {children}
        </Link>
      );
    }
  }
);

LinkButton.displayName = 'LinkButton';
