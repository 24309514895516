import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import { Heading, Text } from '../../design/Typography';
import Button from '../../design/Button';
import Spacer from '../../design/Spacer';

import useSearchParams from '@/hooks/useSearchParams';

import { useSentry } from '../../ServerConfig';

import crashImage from '../../assets/images/crash.svg';

import styles from './AppErrorBoundary.module.pcss';

function isVersionSkewError(error: Error) {
  return error.name === 'ModuleNotFoundError';
}

function hasReloadedNewVersion(searchParams: URLSearchParams): boolean {
  return searchParams.get('slideruleNewVersion') === 'true';
}

function setNewVersionParam(searchParams: URLSearchParams): URLSearchParams {
  const newParams = new URLSearchParams(searchParams);
  newParams.set('slideruleNewVersion', 'true');

  return newParams;
}

function getHref(searchParams: URLSearchParams) {
  let href = window.location.origin + window.location.pathname;

  if (Array.from(searchParams.keys()).length > 0) {
    href += `?${searchParams.toString()}`;
  }

  return href;
}

function reloadNewVersion(searchParams: URLSearchParams) {
  const newParams = setNewVersionParam(searchParams);

  window.location.href = getHref(newParams);
}

const AppErrorBoundary = ({ children }: $TSFixMe) => {
  const [searchParams] = useSearchParams();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error) => {
        if (isVersionSkewError(error)) {
          if (!hasReloadedNewVersion(searchParams)) {
            reloadNewVersion(searchParams);
            return;
          }
        }
        if (useSentry) {
          Sentry.captureException(error);
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorFallback = (props: FallbackProps) => {
  const { error } = props;

  if (isVersionSkewError(error)) {
    return null;
  }

  return (
    <div role="alert" className={styles.container}>
      <img className={styles.image} src={crashImage} alt="The page crashed" />
      <Spacer size="md" />
      <Heading className={styles.heading} weight="bold">
        Something went wrong on our end
      </Heading>
      <Text size="md">
        Please refresh the page. If this continues to happen, reach out to{' '}
        <a href="mailto:support@sliderule.io">support</a>.
      </Text>
      <Spacer size="lg" />
      <Button
        className={styles.button}
        onClick={() => {
          window.location.reload();
        }}
      >
        Refresh
      </Button>
    </div>
  );
};

export default AppErrorBoundary;
