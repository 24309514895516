import * as LDCLient from 'launchdarkly-js-client-sdk';
import type { User } from '@/types';

import { environment } from '@/ServerConfig';

import configManager from '@/utils/configManager';

import { hasProperty, getEmail } from './index';

export function getClientId() {
  const isCypress = __CYPRESS_ENV__ === 'true';

  const isOnPrem =
    environment === 'block' || environment === 'varo' || environment === 'dave';

  if (isCypress) {
    return '63daeb3613539d12af3f81e9';
  }

  if (environment === 'localhost') {
    return '63cac4d6aed42e135f93a2a2';
  }

  if (environment === 'dev') {
    return '63cabc8278d7d91230459de1';
  }

  if (environment === 'staging') {
    return '63dd54e48283d7136274436a';
  }

  if (environment === 'sandbox') {
    return '640b9333d89c4a13ec404fd0';
  }

  if (isOnPrem) {
    return '64ad7020d2c99013403f55df';
  }

  // all prod environments
  return '63cabc8278d7d91230459de2';
}

let initialized = false;

let ldClient: LDCLient.LDClient;

if (!initialized) {
  ldClient = LDCLient.initialize(
    getClientId(),
    {
      kind: 'user',
      key: 'anonymous',
      environment: configManager.getConfigValue('SHIELDRULE_ENVIRONMENT'),
    },

    { streaming: false, sendEvents: false }
  );

  initialized = true;
}

export function identifyUser(user: User) {
  const email = getEmail(user);

  ldClient.identify({
    kind: 'user',
    key: String(user.id),
    environment: configManager.getConfigValue('SHIELDRULE_ENVIRONMENT'),
    email,
  });
}

const isDev =
  configManager.getConfigValue('SHIELDRULE_ENVIRONMENT') === 'localhost';
const isCypress = __CYPRESS_ENV__ === 'true';
const isVitest = process.env.NODE_ENV === 'test';

export const flagClient = {
  getFlag(flag: string): boolean {
    if (isDev || isCypress || isVitest) {
      const item = localStorage.getItem('flag-overrides');
      const overrides = (item ? JSON.parse(item) : {}) as Record<
        string,
        boolean
      >;

      if (hasProperty(overrides, flag)) {
        return overrides[flag];
      }

      return ldClient.variation(flag, false);
    } else {
      return ldClient.variation(flag, false);
    }
  },
  waitUntilReady(): Promise<void> {
    return ldClient.waitForInitialization();
  },
};
