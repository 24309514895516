import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginCallback as OktaCallback } from '@okta/okta-react';

import { shouldUseOkta } from './ServerConfig';
import App from './app';
import { useAuth } from './context/AuthenticationProvider';

/*
  If unauthenticated, this renders the App component as well as appropriate oauth callback routes.
  Otherwise, it just renders the normal App.
*/

const CallbackComponent = (props) => {
  if (shouldUseOkta) {
    return <OktaCallback {...props} />;
  }

  return <Redirect to="/login" />;
};

const AppAndRedirects = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route exact path="/login/callback" component={CallbackComponent} />
        <Route path="*" component={App} />
      </Switch>
    );
  }

  return <App />;
};

export default AppAndRedirects;
