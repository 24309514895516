import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@/context/AuthenticationProvider';
import { shouldUseAuth0 } from '@/ServerConfig';

import useSearchParams from './useSearchParams';

export default function useLogoutIfErrorParamExists() {
  const { logout } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const errorParam = searchParams.get('error');

  React.useEffect(() => {
    if (shouldUseAuth0 && errorParam && errorParam === 'unauthorized') {
      logout({
        redirectTo: `${window.location.origin}?error=unauthorized_email`,
      });
    }
  }, [errorParam, logout, location]);
}
