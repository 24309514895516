import * as React from 'react';
import { Devtools as _Devtools } from './components/Devtools';
import { environment } from '@/ServerConfig';

export const Devtools: typeof _Devtools = !(
  environment === 'dev' || environment === 'localhost'
)
  ? (function () {
      return null;
    } as any)
  : _Devtools;
