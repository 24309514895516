import { toast } from 'react-toastify';
import axios from 'axios';
import { environment } from '../ServerConfig';

if (process.env.NODE_ENV === 'test') {
  axios.defaults.adapter = require('axios/lib/adapters/http');
}

if (environment.toLowerCase().includes('block')) {
  axios.defaults.withCredentials = true;
}

const client = axios.create();

export default client;
