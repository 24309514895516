import * as React from 'react';

const DownArrowIcon = ({ stroke = '#A4A5A6', ...rest }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14 5L8 11L2 5"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownArrowIcon;
