import * as React from 'react';

class ModuleNotFoundError extends Error {
  constructor(message: string) {
    super(message);

    this.name = 'ModuleNotFoundError';
  }
}

interface LazyImportedModule {
  default: React.ComponentType<any>;
}

export default function lazy(factory: () => Promise<LazyImportedModule>) {
  return React.lazy(() =>
    factory().catch((err) => {
      console.error(err);
      throw new ModuleNotFoundError(err.message);
    })
  );
}
